<template>
  <div id="ws-search-container">
    <form class="uk-width-1-1 uk-grid-collapse ws-search-form" action="/search" uk-grid>
      <div class="uk-width-auto"><i class="uk-search-icon fa-regular fa-magnifying-glass"></i></div>
      <div class="uk-width-expand">
        <input id="searchToggle" v-model="searchTerm" name="term" class="uk-width-1-1 uk-search-input ws-search-input" @keyup="fetchSearchResults" type="search"
            :placeholder="'SUCHEN'" autocomplete="off" autofocus/>
      </div>
    </form>
  </div>
  <div id="ws-search-ac" class="uk-dropbar uk-dropbar-top uk-padding-remove-bottom uk-padding-remove-top" uk-drop="boundary: true; stretch: x; boundary-x: !.ws-nav-container-desktop; flip: false; offset: 21; mode: hover">
    <div v-for="(item, key) in searchResultList" :key="item.Id">
      <div class="ws-search-ac-item-container uk-grid-medium uk-position-relative uk-cover-container uk-margin-small-bottom uk-margin-small-top" uk-grid>
        <div class="uk-width-auto ws-dropdown-item-image" v-html="item.imageHtml"></div>
        <div class="uk-width-expand">
          <div class="uk-margin-remove-bottom uk-light uk-h2">
            <span v-html="item.name"></span><span v-if="item.articleNumber">&nbsp;|&nbsp;<span v-html="item.articleNumber"></span></span>
          </div>
          <div v-if="item.descShort" v-html="item.descShort" class="uk-text-small"></div>
        </div>
        <a v-if="item.link" :href="item.link" class="uk-position-cover" :title="item.name"></a>
      </div>
      <div v-if="key < (searchResultList.length -1)" class="uk-width-1-1" style="border-bottom: 2px solid rgba(255,255,255,.1)"></div>
    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";
import {drop} from "uikit";

export default {
  name: "SearchAutocompleteDesktop",
  data() {
    return {
      searchTerm: '',
      searchResultList: [],
      timer: null
    }
  },
  methods: {
    fetchSearchResults() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        ApiService.getProductSearchResult({term: this.searchTerm}).then(response => {
          this.searchResultList = response.data
          if (this.searchResultList.length > 0) {
            drop('#ws-search-ac').show()
          }
        })

      }, 250)
    }
  },
  watch: {
    searchResultList: {
      handler(valNew, valBefore) {
        if(valNew.length == 0) {
          drop('#ws-search-ac').hide()
        }
      }
    }
  }

}
</script>
