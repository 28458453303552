import {defineStore} from "pinia"
import ApiService from "../service/ApiService"

export const useCartStore = defineStore('CartStore', {
    state: () => ({
        cartData: {}
    }),
    getters: {
        cartItemsCount(state) {
            if(state.cartData.itemCount) {
                return state.cartData.itemCount
            }
            return 0
        },
        cartItems(state) {
            return state.cartData.Items ?? [];
        },
        totalPrice(state) {
            return state.cartData.TotalPrice ?? 0
        },
        currency(state) {
            return state.cartData.Currency ?? ""
        },
        getLastAddedItem(state) {
            for(let item in state.cartData.Items) {
                if(state.cartData.Items[item].Id === parseInt(state.lastAddedItemId)) {
                    return state.cartData.Items[item]
                }
            }
        }
    },
    actions: {
        fetchCartData(payload) {
            return ApiService.getCartData(payload)
                .then(response => {
                    this.cartData = response.data.data
                    return response
                })
        },
        addCartItem(payload) {
            return ApiService.addCartItem(payload.pid, payload.cartItemsCount).then((response) => {
                this.fetchCartData().then(() => {
                    this.lastAddedItemId = payload.pid
                    this.lastAddedItemTimestamp = Date.now()
                }).catch(function (error) {
                    throw error
                })
                return response
            });
        },
        updateCartItem(payload) {
            return ApiService.updateCartItem(payload.pid, payload.cartItemsCount).then((response) => {
                this.fetchCartData().then(() => {
                    return response
                }).catch(function (error) {
                    throw error
                })
            });
        },
        removeCartItem(payload) {
            return ApiService.removeCartItem(payload.pid).then((response) => {
                this.fetchCartData().then(() => {
                    return response
                }).catch(function (error) {
                    throw error
                })
            });
        },
        hideCartItem() {
            this.showCartItem = false
        }
    }
})