import {createApp} from "vue";
import {createPinia} from "pinia";
import CartButton from "./components/CartButton.vue";
import CartAmountButton from "./components/CartAmountButton.vue";
import CartMenuIcon from "./components/CartMenuIcon.vue";
import SearchAutocompleteDesktop from "./components/SearchAutocompleteDesktop.vue";
import SearchAutocompleteMobile from "./components/SearchAutocompleteMobile.vue";
import FormOrder from "./components/FormOrder.vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import DealerSearch from "./components/DealerSearch.vue"
import DeliveryAddressManagement from "./components/DeliveryAddressManagement.vue";

const pinia = createPinia()

const cId = ((id) => {
    return document.getElementById(id)
})

if(cId('searchAutocompleteDesktop')) {
    createApp(SearchAutocompleteDesktop).mount('#searchAutocompleteDesktop');
}

if(cId('searchAutocompleteMobile')) {
    createApp(SearchAutocompleteMobile).mount('#searchAutocompleteMobile');
}

if(cId('ws-form-order')) {
    createApp(FormOrder).mount('#ws-form-order');
}

if(cId('deliveryAddressManagement')) {
    createApp(DeliveryAddressManagement).use(pinia).mount('#deliveryAddressManagement');
}


const ElementListCartButtons = [...document.querySelectorAll('.ws-app-cart-button')];
ElementListCartButtons.forEach(ElementCartButton => {
    let tmpApp = createApp(CartButton);
    tmpApp.config.globalProperties.pid = ElementCartButton.getAttribute('data-pid');
    tmpApp.use(pinia);
    tmpApp.mount(ElementCartButton);
});

const ElementListCartAmountButtons = [...document.querySelectorAll('.ws-app-cart-item-amount')];
ElementListCartAmountButtons.forEach(ElementCartAmountButton => {

    let tmpApp = createApp(CartAmountButton);
    tmpApp.config.globalProperties.dataItemId = ElementCartAmountButton.getAttribute('data-item');
    tmpApp.config.globalProperties.dataItemCount = ElementCartAmountButton.getAttribute('data-count');
    tmpApp.use(pinia);
    tmpApp.mount(ElementCartAmountButton);
});

const MenuCartIconList = [...document.querySelectorAll('.ws-cart-menu-icon')];
MenuCartIconList.forEach(MenuCartIcon => {
    let tmpApp = createApp(CartMenuIcon);
    tmpApp.use(pinia);
    tmpApp.mount(MenuCartIcon);
});


if(cId('ws-dealer-search')) {
    createApp(DealerSearch)
        .use(VueGoogleMaps, {
            load: {
                apiKey: 'AIzaSyDYXXJNZMYfsyJx8y9ICJhZwNjuHlH4ONs',
                libraries: 'places'
            }
        })
        .mount('#ws-dealer-search');
}
