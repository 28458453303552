<template>
  <GMapMap
      :center="center"
      :zoom="7"
      style="width: 100%; height: 400px;"
  >
    <GMapMarker
        :position="{ lat: 10, lng: 10 }"
        :clickable="true"
        :draggable="true"
    />
  </GMapMap>
</template>

<script>
export default {
  name: 'DealerSearch',
  data() {
    return {
      center: {
        lat: 51.5287718,
        lng: -0.2416804
      }
    }
  }
}
</script>


