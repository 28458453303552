<template>
  <a href="/cart" uk-tooltip="Zum Warenkorb"><i class="fa-regular fa-cart-shopping"></i>
    <span v-if="cartStore.cartItemsCount > 0" class="ws-cart-itemcount-badge uk-badge">{{ cartStore.cartItemsCount }}</span>
  </a>
</template>

<script>
import {useCartStore} from "../store/CartStore"

export default {
  name: "CartMenuIcon",
  created() {
    this.cartStore.fetchCartData().then((data) => {
    }).catch(error => {
      console.log(error)
    })
  },
  setup() {
    const cartStore = useCartStore()
    return {
      cartStore
    }
  }
}
</script>