<template>
  <div :class="containerClass">
    <label
        v-if="label"
        :for="uuid"
    >
      {{ label }}
      <span v-if="required"><sup>*</sup></span>
    </label>
    <select
        :placeholder="getPlaceholder"
        class="uk-input"
        :class="{ 'ws-input-error': hasError }"
        :value="modelValue"
        :id="uuid"
        v-bind="{
          ...$attrs,
          onInput: updateValue
        }"
    >
      <option
          v-for="(optionDisplay, optionValue) in optionList"
          :value="optionValue"
          :key="optionValue"
      >{{optionDisplay}}</option>
    </select>
  </div>
</template>

<script>
import SetupFormComponent from "../features/SetupFormComponent.js";
import UniqueID from "../features/UniqueID.js";

export default {
  name: "BaseSelect",
  props: {
    label: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    optionList: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: {}
    },
    required: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const {updateValue} = SetupFormComponent(props, context)
    const uuid = UniqueID().getID()

    return {
      updateValue,
      uuid
    }
  },
  computed: {
    hasError() {
      return (this.errorMessage)
    },
    getPlaceholder() {
      if(this.placeholder != '') {
        return this.placeholder
      }
      return ''
    }
  }
}
</script>

