import {defineStore} from "pinia"
import ApiService from "../service/ApiService"
import {data} from "uikit/src/js/util";

export const useDeliveryAddressStore = defineStore('DeliveryAddressStore', {
    state: () => ({
        listData: {}
    }),
    getters: {
        getItems(state) {
            return state.listData.items ?? [];
        },
        getCurrentDeliveryAddressId(state) {
            return state.listData.current_delivery_address_id ?? 0;
        },
        getCountryCodeList(state) {
            return state.listData.country_code_list ?? [];
        }
    },
    actions: {
        loadDeliveryAddressList() {
            return ApiService.getDeliveryAddressListForCurrentUser()
                .then(response => {
                        this.listData = response.data
                        return response
                    }
                )
        },
        setCurrentDeliveryAddress(id) {
            return ApiService.setCurrentDeliveryAddress(id).then((response) => {
                this.listData = response.data
                return response
            })
        },
        editDeliveryAddress(data) {
            return ApiService.editDeliveryAddress(data).then((response) => {
                return response
            })
        },
        getItem(addressId) {
            let addressItem = {}
            this.listData.items.forEach((item, index) => {
                let itemAddressId = parseInt(item.Id)
                if(itemAddressId === addressId) {
                    addressItem = item
                    return
                }
            })

            return addressItem
        },
        deleteDeliveryAddress(addressId) {
            return ApiService.deleteDeliveryAddress(addressId).then((response) => {
                this.listData = response.data
                return response
            })
        }
    }
})